// $primary: #a51616;
@import url('https://fonts.googleapis.com/css?family=Montserrat|Shadows+Into+Light');

$primary: #8bd3f5;
/* MAIN COLOR */
$secondary: #1a2d45;
/* SECONDARY COLOR */
$blk: #333;
/* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa;
$border-radius: 0px;
/* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;



body {
	font-size: 16px;
	font-family: 'Montserrat',
	sans-serif;

}
a,
h1,
h2,
h3,
h4 {
	font-family: 'Shadows Into Light',
	cursive;

}


.flash {
	display: none;
}

nav {
	z-index: 1000;
}

.row {
	margin-right: 0px;
	margin-left: 0px;
}

.navbar-default {
	background: url('../img/backpattern.png');
	background-repeat: no-repeat;
	background-position:  center;
	border-radius: 0px;
	border: 0px;
	width: 100% !important;
}


.navbar-default-overlay {
	background-color: rgba(255, 255, 255, 0.65);
}

.navbarFixed {
	position: fixed;
	width: 100%;
	top: 0;
	z-index: 999;
	// padding: 20px 0;
	background-color: $blk;

	@media (max-width: 767px) {
		// padding: 0;
	}
}

nav.navbar {
	z-index: 1000;
	border: none;
	border-radius: $border-radius;

	.navbar-nav {
		>li>a {
			text-align: center;
			margin-top: 22px;
			display: flex;
			align-items: center;
			color: $blk;
			font-size: 1.6em;
			text-shadow: 0 0 2px $wht;

			@media (max-width: 767px) {
				margin-top: 0;
				padding: 8px;
				display: inline-block;
			}

			&:focus,
			&:active {
				background: transparent;
				color: $blk;
				outline: 0;
			}

			&:hover {
				background: lighten($blk, 20%);
				color: $wht;

				@media (max-width: 1024px) {
					background: transparent;
					color: $blk;
					outline: 0;
				}
			}
		}
	}
}

.navbar-right {
	margin-top: 0px;
}

.navbar-toggle {
	margin: 20px 15px 8px 0px;
	border: 1px solid transparent;
	border-radius: 4px;
}
.navbar {
	margin-bottom: 0px;
}

/** LOGIN FORM **/

@mixin btn1 {
	text-align: center;
	margin: 0 auto;
	border: 1px solid $primary;
	border-radius: $border-radius;
	background: $primary;
	color: $wht;
	padding: 0.5em 2em;

	&:hover {
		background: lighten($primary, 10%);
		color: lighten($wht, 10%);
		box-shadow: 0px 0px 3px lighten($blk, 10%);
	}
}

@mixin btn2 {
	@include btn1;
	background: darken($wht, 5%);
	color: $primary;
	border: none;

	&:hover {
		background: $wht;
		color: $primary;
		border: none;
	}
}

.modal-dialog {
	max-width: 300px;
	text-align: center;
	margin: 6em auto;

	.close {
		display: none;
	}

	.modal-content {
		color: $primary;

		h2 {
			text-align: center;
		}
	}

	input {
		border: none;
		border-bottom: 1px solid darken($primary, 20%);
		text-align: center;
	}

	button {
		background: transparent;
		color: $wht;
		display: block;
		border: none;
		padding: 1em 2em;
		margin: 0 auto;

		&:hover {
			background: transparent;
			color: #fff;
			box-shadow: none;
			text-shadow: 0px 0px 3px $blk;
			border: none;
		}
	}

	input[type="submit"] {
		@include btn1;
		display: block;
		width: 75%;
	}

}

.modal-header,
.modal-footer {
	background: $primary;
	color: $wht;
}

input#username {
	margin-bottom: 20px;
}

/** END LOGIN FORM **/

footer {
	padding: 50px 0px 20px;
	background: $primary;
	color: $footerLinks;

	a {
		color: $footerLinks;

		&:hover {
			color: lighten($footerLinks, 10%);
		}
	}
}

input#username,
input#password {
	width: 100%;
}

@media (max-width: 1024) {

	input#username,
	input#password {
		font-size: 16px;
	}
}

select {
	-moz-appearance: none;
	text-indent: 0.01px;
	text-overflow: '';
}

/* ========= BUTTONS ============ */

.btn-download,
.btn-download:focus,
.btn-download:active,
.btn-download:visited {
	background-color: $primary;
	border-color: $wht;
	color: $wht;
	padding: 10px 20px;
	font-size: 19px;
	text-transform: uppercase;
	border-radius: 100px;
	transition: all 1s;
	-webkit-transition: all 1s;
	-moz-transition: all 1s;
	-o-transition: all 1s;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

}

.btn-download:hover {
	background-color: $secondary;
	color: $wht;

}

.btn-download {
	margin-bottom: 15px !important;
	display: block;
	margin: 0 auto;
	max-width: 175px;
	margin-top: 50px;


	@media (max-width: 767px) {

		margin-top: 10px;
	}



	@media (max-width: 1024px) {

		margin-top: 10px;
	}
}

/* ========== HEADER ============== */

.header {
	background-image: url('../img/banner.jpg');
	background-repeat: no-repeat;
	background-position: 50% 10%;
	background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	-webkit-background-size: cover;
	text-align: center;

	@media (max-width: 1199px) {
		background-position: 20% 20%;
	}

	@media (max-width: 1024px) {
		background-position: 100% 20%;
	}

	@media (max-width: 768px) {
		background-position: 90% 20%;
	}

}

.header-overlay {
	background-color: rgba(31, 31, 31, 0.5);
	padding: 150px 0px;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);


	@media (max-width: 1024px) {
		padding: 0px;

	}

	@media (min-width:1024px) and (max-width: 1199px) {
		padding: 25px 0px;
	}

	@media (min-width:1199px) and (max-width: 1440px) {
		padding: 50px 0px;
	}


}

.header h1 {
	color: $wht;
	font-size: 5em;
	text-align: center;
	text-shadow: 3px 3px $primary;

	@media (max-width: 1023px) {
		font-size: 3em;
	}

	@media (min-width: 1024px) and (max-width: 1199px) {
		font-size: 4em;
	}
}

.header p {
	font-size: 1.75em;
	color: $wht;

	@media (max-width: 767px) {
		font-size: 1.5em;
	}

}

.logo {
	// width: 100%;
	max-height: 100px;
	margin-left: 10px;
	padding: 5px;


	@media (max-width: 1024px) {
		// width: 100%;
		max-height: 70px;
		margin-left: 5px;
		padding: 5px;
	}

}

.header img {
	display: block;
	margin: 0 auto;
	width: 100%;
	max-width: 600px;

	@media (max-width: 1024px) {
		margin-top: 50px;
	}

}


/*=========== Upper CTA ============ */

.upper-cta {
	padding: 50px 0px;
	background: $primary;
	background-image: url('../img/backpattern-right.png');
	background-repeat: repeat-x;
	background-position: 0% 10%;

	@media (max-width: 1280px) {
		background-position: 50% 10%;
	}

	@media (max-width: 767px) {
		padding: 0px;
	}

	@media (min-width: 768px) and (max-width: 1023px) {
		padding: 15px;
	}

}


.upper-cta h1 {
	font-size: 4em;
	color: $wht;
	text-shadow: 2px 2px $secondary;

	@media (max-width: 767px) {
		font-size: 2.5em;
	}


}

.upper-cta p {
	font-size: 1.5em;
	color: $blk;


	@media (max-width: 1024px) {
		font-size: 1.65em;
		// line-height: 40px;
	}


	@media (max-width: 767px) {
		font-size: 1.2em;
	}



}


/* ========== MINIFORM AREA ============== */

.miniform {
	padding: 50px 0px;
	background-image: url('../img/backpattern.png');
	background-repeat: repeat;
	background-position: 60% 60%;
	background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	-webkit-background-size: cover;

	@media (max-width: 1024px) {
		padding: 35px 0px;
		text-align: center;
	}

	@media (max-width: 767px) {
		background-position: 20% 10%;
	}


}

.miniform img {
	width: 100%;
	display: block;
	margin: 0 auto;
	max-width: 650px;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);


	@media (max-width: 1024px) {
		margin-top: 25px;
	}


}

.miniform h1 {
	font-size: 4em;
	color: $secondary;
	text-shadow: 2px 2px $primary;
	padding: 10px;

	@media (max-width: 767px) {
		font-size: 2.5em;
	}

}

.miniform p {
	color: $blk;
	font-size: 1.65em;
	letter-spacing: 1px;
	line-height: 40px;
	padding: 10px;

	@media (max-width: 767px) {
		font-size: 1.2em;
	}


}

/* ========== BENEFITS ============== */


.benefits {
	background-image: url('../img/lower-bg.png');
	background-repeat: no-repeat;
	background-position: 40% 40%;
	background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	-webkit-background-size: cover;
	text-align: center;


	@media (max-width: 1280px) {
		background-position: 80% 10%
	}

	@media (max-width: 767px) {
		background-position: 80% 10%
	}

}

.benefits-overlay {
	padding: 100px;

	@media (max-width: 1199px) {
		padding-top: 80px;
	}


	@media (max-width: 1024px) {
		padding: 0px;
		text-align: center;
		background-color: rgba(31, 31, 31, 0.5);
	}



}

.benefits h1 {
	font-size: 4em;
	color: $secondary;
	text-shadow: 2px 2px $primary;

	@media (max-width: 1024px) {
		font-size: 3em;
	}

	@media (min-width: 1024px) and (max-width: 1199px) {
		font-size: 3.5em;
	}
}

.benefits p {
	font-size: 1.65em;
	letter-spacing: 1px;
	// line-height: 40px;
	// padding: 10px;
	text-align: center;
	color: $blk;


	@media (max-width: 1024px) {
		color: $wht;
		font-size: 1.2em;
		
	}


}


.benefits img {
	width: 100%;
	max-width: 550px;
	display: block;
	margin: 0 auto;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

	@media (max-width: 1199px) {
		margin-top: 50px;
	}


}


/* ========== FEATURES ============== */


.features {
	padding: 50px 0px;

	@media (max-width: 1024px) {
		padding: 0px;
	}


}

.features h1 {
	font-size: 4em;
	color: $secondary;
	text-shadow: 2px 2px $primary;
	text-align: center;

	@media (max-width: 1024px) {
		font-size: 3em;
	}
}

.features h2 {
	font-size: 3em;
	color: $secondary;
	text-shadow: 2px 2px $primary;

	@media (max-width: 1024px) {
		text-align: center;
	}

	@media (min-width:1024px) and (max-width: 1199px) {
		font-size: 2em;
	}

}

.features p {
	font-size: 1.65em;
	letter-spacing: 1px;
	line-height: 30px;
	color: $blk;
	margin-bottom: 50px;

	@media (max-width: 1024px) {
		text-align: center;
		font-size: 1.2em;
	}

	@media (min-width:1024px) and (max-width: 1199px) {
		font-size: 1.35em;
	}
}


.features div i {
	font-size: 200px;
	color: $secondary;
	text-shadow: 2px 2px $primary;
}



.features img {
	width: 100%;
	max-width: 250px;
	display: block;
	margin: 0 auto;


	@media (max-width: 1024px) {
		max-width: 250px;
	}
}

.navbar {
	margin-bottom: 0px;
}